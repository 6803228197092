import React from 'react';
import PropTypes from 'prop-types';
import {styled, useTheme} from "@mui/material/styles";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import {Accordion, AccordionDetails, Box, Grid} from "@mui/material";
import ArrowIcon from "../ArrowIcon";
import useResponsive from "../../hooks/useResponsive";
import {getLegendaryGem} from "../../utils/items";
import GemPowerQuantity from "./GemPowerQuantity";
import GemQuantity from "./GemQuantity";
import {
    GEM_1STAR,
    GEM_2STARS,
    GEM_5STARS,
    UPDATE_TABLE_1STAR,
    UPDATE_TABLE_2STARS,
    UPDATE_TABLE_5STARS
} from "../../constants";
import CustomExpandIcon from "./CustomExpandIcon";

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<CustomExpandIcon/>}
        {...props}
    />
))(() => ({
    backgroundColor: 'rgba(255, 255, 255, .05)'
}));

const LegendaryGemUpgradeTotal = props => {
    const gem = getLegendaryGem(props.gemId)
    const theme = useTheme()
    const mdDown = useResponsive('down', 'md');

    let data = []
    if (gem.type === GEM_1STAR) {
        data = UPDATE_TABLE_1STAR.filter(obj => obj.rank > props.fromRank && obj.rank <= props.toRank)
    } else if (gem.type === GEM_2STARS) {
        data = UPDATE_TABLE_2STARS.filter(obj => obj.rank > props.fromRank && obj.rank <= props.toRank)
    } else if (gem.type === GEM_5STARS) {
        data = UPDATE_TABLE_5STARS.filter(obj => obj.rank > props.fromRank && obj.rank <= props.toRank)
    }

    const totalRank1Gems = data.reduce((accum,item) => accum + item.gemRank1, 0)
    const totalRank3Gems = data.reduce((accum,item) => accum + item.gemRank3, 0)
    const totalRank5Gems = data.reduce((accum,item) => accum + item.gemRank5, 0)

    const totalUpgradeGemPower = data.reduce((a, b) => a + b.upgradeGemPower, 0);
    const totalUpgradeRank1Gems = data.reduce((a, b) => a + b.upgradeRank1Gems, 0);

    return (
        <Box>
            <Accordion sx={{
                backgroundColor: theme.palette.background.DI,
            }}>
                <AccordionSummary
                    aria-controls={`total`}
                    id={`total`}
                    sx={{
                        backgroundColor: theme.palette.background.DI,
                        borderRadius: 1,
                        paddingLeft: mdDown ? 0 : 3
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item md={3} sm={3} xs={3}>
                            <GemPowerQuantity quantity={totalUpgradeGemPower}/>
                        </Grid>
                        <Grid item md={3} sm={3} xs={3}>
                            <GemQuantity gemId={gem.id} quantity={props.fromRank === 1 ? totalRank1Gems + 1 : totalRank1Gems}
                                         rank={1}/>
                        </Grid>
                        <Grid item md={3} sm={3} xs={3}>
                            <GemQuantity gemId={gem.id} quantity={totalRank3Gems} rank={3}/>
                        </Grid>
                        <Grid item md={3} sm={3} xs={3}>
                            <GemQuantity gemId={gem.id} quantity={totalRank5Gems} rank={5}/>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails sx={{
                    backgroundColor: theme.palette.background.DI,
                    borderTop: `1px solid ${theme.palette.grey[700]}`,
                }}>
                    <Grid container spacing={2}>
                        <Grid item md={3} sm={3} xs={3} style={{paddingTop: 30}}>
                            {props.fromRank} <ArrowIcon type={'right'}/> {props.toRank}
                        </Grid>
                        <Grid item md={4} sm={4} xs={4}>
                            <GemPowerQuantity quantity={totalUpgradeGemPower}/>
                        </Grid>
                        <Grid item md={3} sm={3} xs={3}>
                            <GemQuantity
                                gemId={gem.id}
                                quantity={props.fromRank === 1 ? totalUpgradeRank1Gems + 1 : totalUpgradeRank1Gems}
                                rank={1}/>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

LegendaryGemUpgradeTotal.propTypes = {
    gemId: PropTypes.number.isRequired,
    fromRank: PropTypes.number.isRequired,
    toRank: PropTypes.number.isRequired
};

export default LegendaryGemUpgradeTotal;