import React from 'react';
import PropTypes from 'prop-types';
import {getLegendaryGem} from "../../utils/items";
import {IMAGE_PATH} from "../../constants";


const LegendaryGemIcon = props => {
    const gem = getLegendaryGem(props.id)
    return (
        <img src={`${IMAGE_PATH}/legendaryGems/${gem.id}.webp`}
             className={`di-gem-image`}
             alt={gem.name}
            // style={{borderRadius: '5%'}}
             onError={({currentTarget}) => {
                 currentTarget.onerror = null; // prevents looping
                 currentTarget.src = "/static/images/placeholder.png";
             }}
        />
    );
};

LegendaryGemIcon.propTypes = {
    id: PropTypes.number.isRequired
};

export default LegendaryGemIcon;