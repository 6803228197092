import {getItem} from "../utils/items";
import {CLEAR_SELECTED_ITEMS, SET_ITEM_ADD, SET_ITEM_REMOVE} from "../constants";

export default (state, action) => {
    switch (action.type) {
        case SET_ITEM_ADD:
            return {...state, selectedItems: [...state.selectedItems, getItem(action.payload)]};
        case SET_ITEM_REMOVE:
            return {...state, selectedItems: state.selectedItems.filter((item) => item.id !== action.payload)};
        case CLEAR_SELECTED_ITEMS:
            return {...state, selectedItems: []};
        default:
            return state;
    }
};