import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";
import {alpha, styled} from "@mui/material/styles";
import {AppBar, Box, Button, Divider, Link, Typography} from "@mui/material";
import LegendaryGemIcon from "./legendary-gems/LegendaryGemIcon";
import Star from "./stars/Star";
import Stars from "./stars/Stars";

const Changelog = props => {
    const {t} = useTranslation();
    const VersionLine = (props) => {
        return <>
            <Box sx={{p: 1}}>
                <Typography variant={'h3'}>v{props.version} - {props.date}</Typography>
            </Box>
            <Divider/>
            <Box sx={{p: 1}}>
                {props.children}
            </Box>
        </>
    }
    const Added = ({children, className}) => {
        return <div className={className || ''}><span className={'added'}>Added: </span> {children}</div>
    }
    const Completed = ({children, className}) => {
        return <div className={className || ''}><span className={'added'}>Completed:</span> {children}</div>
    }
    const Updated = ({children, className}) => {
        return <div className={className || ''}><span className={'updated'}>Updated:</span> {children}</div>
    }
    const Fixed = ({children, className}) => {
        return <div className={className || ''}><span className={'fixed'}>Fixed:</span> {children}</div>
    }
    const ThanksTo = ({user}) =>{
        let link = '';
        switch (user) {
            case 'Frosty':
                link = <Link target="_blank" href={'https://www.reddit.com/user/Frosty_Specialist136/'}>Frosty_Specialist136</Link>
                break;
            case 'Stvnhawkins':
                link = <Link target="_blank" href={'https://www.reddit.com/user/Stvnhawkins/'}>Stvnhawkins</Link>
                break;
            case 'just_kidding':
                link = <Link target="_blank" href={'https://www.reddit.com/user/_just_kidding_/'}>_just_kidding_</Link>
                break;
            case 'Chateaubriand':
                link = <Typography variant={'span'} color={'primary'}>Chateaubriand Vieira Moura</Typography>
                break;
            case 'jira':
                link = <Link target="_blank" href={'https://crowdin.com/profile/jira-auriel'}>jira-auriel</Link>
                break;
            case 'djtms':
                link = <Link target="_blank" href={'https://crowdin.com/profile/djtms84'}>Tomasz Adamski</Link>
                break;
            case 'veitchmode':
                link = <Link target="_blank" href={'https://crowdin.com/profile/veitchmode'}>El Zed</Link>
                break;
            default:
                break;
        }
        return <span>(Thanks to {link})</span>
    }
    const Gem = ({name, id}) =>{
        return <div className={'changelog-newgem'}><LegendaryGemIcon id={id} /> {name}</div>
    }

    return (
        <RootStyle>
            <div className={'changelog-drawer'}>
                <Button className={'drawer-close'} onClick={e => props.onClose()}>X</Button>
                <VersionLine date={'13/09/24'} version={'2.3.0'}>
                    <Added className={'gems-line'}>
                        <Gem name={t('legendaryGems.entropicWell.name')} id={63}/>
                        <Gem name={t('legendaryGems.coldConfidant.name')} id={64}/>
                        <Gem name={t('legendaryGems.wulfheort.name')} id={65}/>
                    </Added>
                </VersionLine>
                <VersionLine date={'10/09/24'} version={'2.2.9'}>
                    <Added>New "Prayer For Endwinter" Set</Added>
                    <Completed className={'gems-line'}>
                        <Gem name={t('legendaryGems.miseryElixir.name')} id={57}/>
                    </Completed>
                    <Updated>Set items drop difficulties</Updated>
                    <Updated>German, Korean, Polish translation</Updated>
                </VersionLine>
                <VersionLine date={'23/07/24'} version={'2.2.8'}>
                    <Added className={'gems-line'}>
                        <Gem name={t('legendaryGems.lucentWatcher.name')} id={60}/>
                        <Gem name={t('legendaryGems.mossthorn.name')} id={61}/>
                        <Gem name={t('legendaryGems.stormvault.name')} id={62}/>
                    </Added>
                    <Updated>Russian, German, Korean translation</Updated>
                </VersionLine>
                <VersionLine date={'01/06/24'} version={'2.2.7'}>
                    <Added className={'gems-line'}>
                        <Gem name={t('legendaryGems.hiltOfManyRealms.name')} id={59}/>
                    </Added>
                    <Completed className={'gems-line'}>
                        <Gem name={t('legendaryGems.tearOfTheComet.name')} id={56}/>
                        <Gem name={t('legendaryGems.grimRhythm.name')} id={55}/>
                        <Gem name={t('legendaryGems.volatilityShard.name')} id={37}/>
                    </Completed>
                    <Updated>Polish, German translation</Updated>
                </VersionLine>
                <VersionLine date={'09/04/24'} version={'2.2.6'}>
                    <Added className={'gems-line'}>
                        <Gem name={t('legendaryGems.tearOfTheComet.name')} id={56}/>
                        <Gem name={t('legendaryGems.miseryElixir.name')} id={57}/>
                        <Gem name={t('legendaryGems.roilingConsequence.name')} id={58}/>
                    </Added>
                    <Updated className={'gems-line'}>
                        <Gem name={t('legendaryGems.volatilityShard.name')} id={37}/>
                    </Updated>
                    <Completed>German, Korean, Russian, Spanish translations</Completed>
                </VersionLine>
                <VersionLine date={'26/03/24'} version={'2.2.5'}>
                    <Updated className={'gems-line'}>
                        <Gem name={t('legendaryGems.volatilityShard.name')} id={37}/>
                    </Updated>
                    <Completed className={'gems-line'}>
                        <Gem name={t('legendaryGems.igneousScorn.name')} id={53}/>
                        <Gem name={t('legendaryGems.grimRhythm.name')} id={55}/>
                    </Completed>
                    <Completed>Spanish translation</Completed>
                </VersionLine>
                <VersionLine date={'23/02/24'} version={'2.2.4'}>
                    <Completed className={'gems-line'}>
                        <Gem name={t('legendaryGems.trickshot.name')} id={13}/>
                        <Gem name={t('legendaryGems.cutthroat.name')} id={18}/>
                        <Gem name={t('legendaryGems.eyeOfUnyielding.name')} id={48}/>
                        <Gem name={t('legendaryGems.ironbane.name')} id={45}/>
                    </Completed>
                    <Updated className={'gems-line'}><Gem name={t('legendaryGems.grimRhythm.name')} id={55}/></Updated>
                    <Updated>Polish translation</Updated>
                    <Completed>German translation</Completed>
                    <Completed>Korean translation</Completed>
                    <Completed>Russian translation</Completed>
                </VersionLine>
                <VersionLine date={'13/02/24'} version={'2.2.3'}>
                    <Updated className={'gems-line'}><Gem name={t('legendaryGems.laughter.name')} id={7}/></Updated>
                    <Added>German translation <ThanksTo user={'veitchmode'}/> </Added>
                </VersionLine>
                <VersionLine date={'20/01/24'} version={'2.2.2'}>
                    <Updated className={'gems-line'}><Gem name={t('legendaryGems.igneousScorn.name')} id={53}/><Gem name={t('legendaryGems.hunger.name')} id={22}/></Updated>
                    <Completed>Korean and Russian translations</Completed>
                    <Added>Polish translation (not completed) <ThanksTo user={'djtms'}/> </Added>
                </VersionLine>
                <VersionLine date={'19/01/24'} version={'2.2.1'}>
                    <Added className={'gems-line'}><Gem name={t('legendaryGems.grimRhythm.name')} id={55}/></Added>
                    <Completed className={'gems-line'}>All <Star active/> and <Stars amount={5} activeNum={5}/> gems</Completed>
                    <Completed className={'gems-line'}>Awakening gems for <Stars amount={2} activeNum={2}/> and almost all <Star active/> (Unrefined Passage is still missing)</Completed>
                </VersionLine>
                <VersionLine date={'18/01/24'} version={'2.2.0'}>
                    <Added>Awakening Legendary Gem information</Added>
                    <Completed className={'gems-line'}> Awakening:
                        <Gem name={t('legendaryGems.blessedPebble.name')} id={15}/>
                        <Gem name={t('legendaryGems.heartstone.name')} id={36}/>
                        <Gem name={t('legendaryGems.kirSling.name')} id={38}/>
                    </Completed>
                </VersionLine>
                <VersionLine date={'16/01/24'} version={'2.1.6'}>
                    <Added>Legendary gem info completion percentage</Added>
                    <Completed className={'gems-line'}><Gem name={'Gloom Cask'} id={43}/></Completed>
                </VersionLine>
                <VersionLine date={'15/01/24'} version={'2.1.5'}>
                    <Added>Korean translation <ThanksTo user={'jira'}/></Added>
                    <Updated>Spanish and Russian translation</Updated>
                    <Completed className={'gems-line'}><Gem name={'Exigent Echo'} id={44}/></Completed>
                </VersionLine>
                <VersionLine date={'10/01/24'} version={'2.1.0'}>
                    <Added>New rank selector for Legendary Gem (Allows to select starting and ending rank)</Added>
                    <Added>"Show difference" checkbox for gem info</Added>
                    <Updated>Spanish translation <ThanksTo user={'Frosty'}/></Updated>
                    <Updated className={'gems-line'}><Gem name={'Igneous Scorn'} id={53}/></Updated>
                </VersionLine>
                <VersionLine date={'09/01/24'} version={'2.0.0'}>
                    <Added className={'gems-line'}>
                        <Gem name={'Unrefined Passage'} id={52}/>
                        <Gem name={'Igneous Scorn'} id={53}/>
                        <Gem name={'Maw Of The Deep'} id={54}/>
                    </Added>
                    <Added>"Angmet's Conflagration" Set</Added>
                    <Added>Set item drop difficulties</Added>
                    <Added>Changelog</Added>
                    <Added>Portuguese translation (Incomplete) <ThanksTo user={'Chateaubriand'}/></Added>
                    <Updated>Updated dungeons drop table <ThanksTo user={'Stvnhawkins'}/></Updated>
                    <Updated>Russian translation <ThanksTo user={'just_kidding'}/></Updated>
                </VersionLine>
                <VersionLine date={'21/11/23'} version={'1.9.3'}>
                    <Updated>Spanish translation <ThanksTo user={'Frosty'}/></Updated>
                    <Updated className={'gems-line'}><Gem name={'Spiteful Blood'} id={51}/></Updated>
                </VersionLine>
                <VersionLine date={'10/10/23'} version={'1.9.2'}>
                    <Completed className={'gems-line'}><Gem name={'Vipers Bite'} id={49}/></Completed>
                </VersionLine>
                <VersionLine date={'05/10/23'} version={'1.9.1'}>
                    <Updated className={'gems-line'}><Gem name={'Kir Sling'} id={38}/></Updated>
                </VersionLine>
                <VersionLine date={'02/10/23'} version={'1.9.0'}>
                    <Added>Tristam dungeon</Added>
                    <Updated className={'gems-line'}><Gem name={'Vipers Bite'} id={49}/></Updated>
                </VersionLine>
                <VersionLine date={'25/09/23'} version={'1.8.0'}>
                    <Added className={'gems-line'}>
                        <Gem name={'Eye of Unyielding'} id={48}/>
                        <Gem name={'Vipers Bite'} id={49}/>
                        <Gem name={'Void Spark'} id={50}/>
                        <Gem name={'Spiteful Blood'} id={51}/>
                    </Added>
                </VersionLine>
                <VersionLine date={'11/07/23'} version={'1.7.2'}>
                    <Updated className={'gems-line'}><Gem name={'Starfire Shard'} id={47}/></Updated>
                </VersionLine>
                <VersionLine date={'27/06/23'} version={'1.7.1'}>
                    <Updated>Spanish translation <ThanksTo user={'Frosty'}/></Updated>
                </VersionLine>
                <VersionLine date={'19/06/23'} version={'1.7.0'}>
                    <Added className={'gems-line'}>
                        <Gem name={'Exigent Echo'} id={44}/>
                        <Gem name={'Ironbane'} id={45}/>
                        <Gem name={'Mothers Lament'} id={46}/>
                        <Gem name={'Starfire Shard'} id={47}/>
                    </Added>
                    <Updated>Translations: Spanish, Russian</Updated>
                </VersionLine>
                <VersionLine date={'18/06/23'} version={'1.6.0'}>
                    <Added>"Seeds of Sown Gold" Set</Added>
                </VersionLine>
                <VersionLine date={'23/04/23'} version={'1.5.3'}>
                    <Updated>Items tooltip</Updated>
                    <Updated>Css versions</Updated>
                </VersionLine>
                <VersionLine date={'23/04/23'} version={'1.5.0'}>
                    <Added>Normal gems calculator</Added>
                </VersionLine>
                <VersionLine date={'23/04/23'} version={'1.4.0'}>
                    <Updated>Russian translation <ThanksTo user={'just_kidding'}/> </Updated>
                </VersionLine>
                <VersionLine date={'10/04/23'} version={'1.3.3'}>
                    <Updated>Spanish translation <ThanksTo user={'Frosty'}/></Updated>
                </VersionLine>
                <VersionLine date={'08/04/23'} version={'1.3.2'}>
                    <Updated className={'gems-line'}><Gem name={'Pain clasp'} id={42}/></Updated>
                </VersionLine>
                <VersionLine date={'05/04/23'} version={'1.3.1'}>
                    <Fixed>2-star totals</Fixed>
                    <Updated className={'gems-line'}><Gem name={'Gloom Cask'} id={43}/></Updated>
                </VersionLine>
                <VersionLine date={'04/04/23'} version={'1.3.0'}>
                    <Added className={'gems-line'}>
                        <Gem name={'Lo\'s Focused Gaze'} id={41}/>
                        <Gem name={'Pain Clasp'} id={42}/>
                        <Gem name={'Gloom Cask'} id={43}/>
                    </Added>
                    <Added>Banquet items set</Added>
                    <Added>Dread Reaver dungeon</Added>
                    <Updated className={'gems-line'}><Gem name={'Concentrated Will'} id={40}/></Updated>
                    <Updated>Spanish translation</Updated>
                </VersionLine>
                <VersionLine date={'08/01/23'} version={'1.2.2'}>
                    <Updated className={'gems-line'}>
                        <Gem name={'Kir Sling'} id={38}/>
                        <Gem name={'Hellfire Fragment'} id={39}/>
                        <Gem name={'Concentrated Will'} id={40}/>
                    </Updated>
                    <Fixed>2 star total gem power</Fixed>
                </VersionLine>
                <VersionLine date={'21/12/22'} version={'1.2.0'}>
                    <Added className={'gems-line'}>
                        <Gem name={'Heartstone'} id={36}/>
                        <Gem name={'Volatility Shard'} id={37}/>
                        <Gem name={'Kir Sling'} id={38}/>
                        <Gem name={'Hellfire Fragment'} id={39}/>
                        <Gem name={'Concentrated Will'} id={40}/>
                    </Added>
                </VersionLine>
                <VersionLine date={'08/12/22'} version={'1.1.0'}>
                    <Added>Spanish translation <ThanksTo user={'Frosty'}/></Added>
                    <Completed className={'gems-line'}>
                        <Gem name={'Frozen Heart'} id={35}/>
                        <Gem name={'Abiding curse'} id={25}/>
                    </Completed>
                </VersionLine>
                <VersionLine date={'08/10/22'} version={'1.0.5'}>
                    <Updated className={'gems-line'}>
                        <Gem name={'Cutthroat'} id={18}/>
                        <Gem name={'Frozen Heart'} id={35}/>
                    </Updated>
                    <Fixed>Upgrade table</Fixed>
                </VersionLine>
                <VersionLine date={'05/10/22'} version={'1.0.0'}>
                    <Added>Legendary Gems</Added>
                </VersionLine>
                <VersionLine date={'28/09/22'} version={'0.8.0'}>
                    <Added>New "Gloomguide" set</Added>
                    <Added>Russian translation (incomplete)</Added>
                </VersionLine>
                <VersionLine date={'19/08/22'} version={'0.5.0'}>
                    <Updated>Typography</Updated>
                    <Fixed>Dungeon buttons</Fixed>
                </VersionLine>
                <VersionLine date={'16/08/22'} version={'0.3.0'}>
                    <Added>BuyMeACoffee</Added>
                </VersionLine>
                <VersionLine date={'15/08/22'} version={'0.2.1'}>
                    <Updated>Translations</Updated>
                </VersionLine>
                <VersionLine date={'14/08/22 '} version={'0.2.0'}>
                    <Added>Internationalization</Added>
                </VersionLine>
                <VersionLine date={'13/08/22'} version={'0.1.0'}>
                    <Added>Sets</Added>
                </VersionLine>
            </div>
        </RootStyle>
    );
};

const DRAWER_WIDTH = 280;

const RootStyle = styled(AppBar)(({theme}) => ({
    boxShadow: 'none',
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
    },
}));

Changelog.propTypes = {};

export default Changelog;
