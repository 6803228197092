import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Box, Container, Grid, Tab, Typography} from "@mui/material";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import i18n from "i18next";
import {useTranslation} from "react-i18next";
import Page from "../../components/Page";
import LegendaryGemsList from "../../components/legendary-gems/LegendaryGemsList";
import Star from "../../components/stars/Star";
import Stars from "../../components/stars/Stars";
import {GEM_1STAR, GEM_2STARS, GEM_5STARS} from "../../constants";
import LegendaryGemSearch from "../../components/legendary-gems/LegendaryGemSearch";
import {useStoreState} from "../../store";
import LegendaryGemsFilteredList from "../../components/legendary-gems/LegendaryGemsFilteredList";
import ComingSoon from "../../components/coming-soon/ComingSoon";
import LegendaryGemConfigurator from "../../components/legendary-gems/LegendaryGemConfigurator";
import useResponsive from "../../hooks/useResponsive";

const LegendaryGems = props => {
    const {t} = useTranslation();
    const gemState = useStoreState('legendaryGems');
    const [selectedTab, setSelectedTab] = React.useState('1');
    const mdDown = useResponsive('down', 'md');

    const handleChange = (event, newTab) => {
        // dispatch({
        //     'type': CLEAR_SELECTED_LEGENDARY_GEM
        // })
        setSelectedTab(newTab);
    };

    // useEffect(() => {
    //     fetch('/data/legendaryGems.json')
    //         .then((response) => {
    //             return response.json();
    //         })
    //         .then((data) => {
    //             console.log(data);
    //         });
    // }, []);

    return (
        <Page title={t("pageTitle.legendaryGems")}>
            <Container maxWidth="xl">
                <Typography variant={'h3'} sx={{marginBottom: 3}}>
                    {t("pageTitle.legendaryGems")}
                    <LegendaryGemSearch/>
                </Typography>

                {gemState.searchValue ?
                    <LegendaryGemsFilteredList />
                    :
                    <TabContext value={selectedTab}>
                        <Box style={{marginBottom:20}}>
                            <TabList onChange={handleChange} aria-label="Legendary gems stars selector">
                                <Tab label={<Star active/>} value="1"/>
                                <Tab style={{display: 'block'}} label={<Stars amount={2} activeNum={2}/>} value="2"/>
                                <Tab style={{display: 'block'}} label={<Stars amount={5} activeNum={2} unknownNum={3}/>}
                                     value="3"/>
                            </TabList>
                        </Box>
                        <Grid container>
                            <Grid item xs={12} md={mdDown ? 12 : 3} style={{borderRight: '1px solid #343434'}}>
                                <TabPanel value="1">
                                    <LegendaryGemsList type={GEM_1STAR}/>
                                </TabPanel>
                                <TabPanel value="2">
                                    <LegendaryGemsList type={GEM_2STARS}/>
                                </TabPanel>
                                <TabPanel value="3">
                                    <LegendaryGemsList type={GEM_5STARS}/>
                                </TabPanel>
                            </Grid>
                            <Grid item xs={12} md={8} sm={12}>
                                <LegendaryGemConfigurator/>
                            </Grid>
                        </Grid>
                    </TabContext>
                }
            </Container>
        </Page>
    );
};

LegendaryGems.propTypes = {

};

export default LegendaryGems;