import React from 'react';
import PropTypes from 'prop-types';
import GemPowerIcon from "./GemPowerIcon";

const GemPowerQuantity = ({quantity}) => {
    return (
        <div style={{position: 'relative'}}>
            <GemPowerIcon/>
            <div className={'quantity'}> {quantity}</div>
        </div>
    );
};

GemPowerQuantity.propTypes = {
    quantity: PropTypes.number
};

export default GemPowerQuantity;