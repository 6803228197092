import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button} from "@mui/material";
import {getItem} from "../../utils/items";
import ItemTooltip from "./ItemTooltip";
import {IMAGE_PATH, IMAGES_VERSION, ITEM_FEET, ITEM_HANDS} from "../../constants";

const SetItem = (props) => {
    const [showTooltipTrigger, setShowTooltipTrigger] = useState(false)
    const item = getItem(props.id)

    return (
        <Button style={{width:80, height:80}}
                onMouseOver={()=>setShowTooltipTrigger(true)}
                onMouseOut={()=>setShowTooltipTrigger(false)}
        >
            <img src={`${IMAGE_PATH}/setItems/${item.id}.webp?v=${IMAGES_VERSION}`}
                 className={`di-item-image ${(item.typeId === ITEM_HANDS || item.typeId === ITEM_FEET) ? 'tall' : ''}`}
                 alt={item.name}
                 style={{borderRadius: '5%'}}
                 onError={({currentTarget}) => {
                     currentTarget.onerror = null; // prevents looping
                     currentTarget.src = "/static/images/placeholder.png";
                 }}/>
            <ins className={`di-item-mask ${(props.selected ? 'item-active' : '')}`}/>
            {showTooltipTrigger && <ItemTooltip item={item}/>}
        </Button>
    );
};

SetItem.propTypes = {
    id: PropTypes.number.isRequired,
    selected: PropTypes.bool
};

export default SetItem;