import {useState} from "react";
import PropTypes from 'prop-types';
// material
import {alpha, styled, useTheme} from '@mui/material/styles';
import {Box, Stack, AppBar, Toolbar, IconButton, Typography, Drawer, Button} from '@mui/material';
// components
import {useTranslation} from "react-i18next";
import Iconify from '../../components/Iconify';
//
// import Searchbar from './Searchbar';
// import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
// import NotificationsPopover from './NotificationsPopover';
import BuyMeACoffee from "./BuyMeACoffee";
import {BUILD_VERSION, LAST_UPDATED} from "../../constants";
import Changelog from "../../components/Changelog";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({theme}) => ({
    boxShadow: 'none',
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
    backgroundColor: alpha(theme.palette.background.default, 0.72),
    [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
    },
}));

const ToolbarStyle = styled(Toolbar)(({theme}) => ({
    minHeight: APPBAR_MOBILE,
    [theme.breakpoints.up('lg')]: {
        minHeight: APPBAR_DESKTOP,
        padding: theme.spacing(0, 5),
    },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
    onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({onOpenSidebar}) {
    const {t} = useTranslation();
    const [drawerOpen, setDrawerOpen] = useState( false )
    return (
        <RootStyle>
            <ToolbarStyle>
                <IconButton onClick={onOpenSidebar} sx={{mr: 1, color: 'text.primary', display: {lg: 'none'}}}>
                    <Iconify icon="eva:menu-2-fill"/>
                </IconButton>

                {/* <Searchbar/> */}
                <Box style={{position:'relative'}} sx={{flexGrow: 1}}>
                    <Typography style={{
                        color: useTheme().palette.text.secondary,
                    }}>
                        <span style={{color:'#00e676'}}>V{BUILD_VERSION}</span>
                        <Button onClick={e=>setDrawerOpen(true)}>{t('lastUpdated')}: {LAST_UPDATED}</Button>
                        <Drawer
                            anchor={'top'}
                            open={drawerOpen}
                            onClose={e => setDrawerOpen(false)}
                        >
                            <Changelog onClose={e => setDrawerOpen(false)}/>
                        </Drawer>
                    </Typography>
                </Box>
                <Stack direction="row" alignItems="center" spacing={{xs: 0.5, sm: 1.5}}>
                    <BuyMeACoffee/>
                    <LanguagePopover/>
                    {/* <NotificationsPopover /> */}
                    {/* <AccountPopover /> */}
                </Stack>
            </ToolbarStyle>
        </RootStyle>
    );
}
