import React from 'react';
import PropTypes from 'prop-types';
import {IMAGE_PATH} from "../../constants";

const GemPowerIcon = props => {
    return (
        <img src={`${IMAGE_PATH}/legendaryGems/gem_fragment.webp`}
             className={`di-gem-image`}
             alt={'gem-power'}
            // style={{borderRadius: '5%'}}
             onError={({currentTarget}) => {
                 currentTarget.onerror = null; // prevents looping
                 currentTarget.src = "/static/images/placeholder.png";
             }}
        />
    );
};

GemPowerIcon.propTypes = {

};

export default GemPowerIcon;