import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";
import Star from "../stars/Star";
import {useStoreState} from "../../store";

const Gem = props => {
    const {t} = useTranslation();
    const gemState = useStoreState('gems');
    const selectedGem = gemState.selectedGem;

    if(!selectedGem){
        return <></>
    }

    return (
        <div className="gem-container" style={{maxWidth: 280, position: 'relative', margin: '0 auto'}}
             data-inventory-color="6" data-core-details="true" data-rank="true">
            <div className="gem-background "/>
            <div className="gem-content">
                <div className="gem-name">
                    <p>{t(`gems.${selectedGem.i18n}`)}</p>
                    <p>({t('rank')} {props.lvl})</p>
                </div>
                <div className="gem-subheader">
                    <div className="gem-icon-bg">
                        <div className="gem-icon" style={{backgroundImage: `url("/static/images/gems/${selectedGem.id}/${props.lvl}.webp")`}}/>
                    </div>
                    <div className="gem-type">
                        {t(`gemTypes.${selectedGem.type}`)}
                    </div>
                </div>
                <div className="gem-info">
                    <ul>
                        <li>
                            +{selectedGem.values[`lvl${props.lvl}`]} {t(`attributes.${selectedGem.attr_i18n}`)}
                        </li>
                        <li>
                            +{3 * props.lvl} % {t('attributes.refinement')} {t('attributes.refinementDescr')}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

Gem.propTypes = {

};

export default Gem;