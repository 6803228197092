import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {Box, Button, List, Modal} from "@mui/material";
import AwakeningGem from "./AwakeningGem";
import {dispatch, useStoreState} from "../../../store";
import {CLEAR_AWAKENING, SET_AWAKENING} from "../../../constants";
import AwakeningBuffs from "./AwakeningBuffs";

const Awakening = () => {
    const {t} = useTranslation();
    const gemState = useStoreState('legendaryGems');
    const {selectedGem} = gemState;
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        dispatch({
            'type': CLEAR_AWAKENING
        })
    }, [selectedGem]);
    
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.default',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    const changeAwakening = (gemId, rank, resonance, gemType) =>{
        const data = {...gemState.awakening}
        data[gemId] = {rank, resonance, gemType}
        dispatch({
            'type': SET_AWAKENING, 'payload': data
        })
    }

    if(!selectedGem.awakening) return ''

    return (
        <div id='awakening'>
            <Button className="di-button di-text" onClick={handleOpen}>{t('awakening.setAwakening')}</Button>
            <AwakeningBuffs />
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box id="awakening-dialog" sx={style}>
                    <List>
                        {Object.keys(selectedGem.awakening).map((key) => {
                            return selectedGem.awakening[key].map(id => {
                                return <AwakeningGem key={id}
                                                     rank={gemState.awakening[id]?.rank}
                                                     gemId={id}
                                                     type={key}
                                                     onChange={changeAwakening}
                                />
                            })
                        })}
                    </List>
                    <Box textAlign={"center"}>
                        <Button className="di-button di-text" onClick={handleClose}>{t('ok')}</Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
};

Awakening.propTypes = {
};

export default Awakening;