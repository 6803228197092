import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";
import {Checkbox, FormControlLabel, Grid, ImageListItem, Rating} from "@mui/material";
import {dispatch, useStoreState} from "../../store";
import {CLEAR_AWAKENING, GEM_1STAR, GEM_2STARS, GEM_5STARS} from "../../constants";
import Star from "../stars/Star";
import Stars from "../stars/Stars";
import ArrowIcon from "../ArrowIcon";
import Awakening from "./Awakening/Awakening";

const LegendaryGem = props => {
    const {t} = useTranslation();
    const [stars, setStars] = React.useState(2);
    const [showDiff, setShowDiff] = React.useState(false);
    const gemState = useStoreState('legendaryGems');
    const selectedGem = gemState.selectedGem;

    useEffect(() => {
        dispatch({
            'type': CLEAR_AWAKENING
        })
    }, [selectedGem]);

    if (!selectedGem) {
        return <></>
    }

    const StarsInfo = () => {
        let stars = '';
        switch (Number(selectedGem.type)) {
            case GEM_1STAR:
                stars = <ImageListItem><Star active/></ImageListItem>
                break;
            case GEM_2STARS:
                stars = <Stars amount={2} activeNum={2}/>
                break;
            // case GEM_5STARS:
            //     stars = <Stars amount={5} activeNum={stars || 2} unknownNum={0}/>
            //     break;
            default:
                stars = ''
                break;
        }
        return stars
    }

    const i18nPrefix = `legendaryGems.${selectedGem.i18n}`

    const getResonance = (rank) => {
        let resonance = 0;
        if (selectedGem.type === GEM_1STAR) {
            resonance = 15 * rank
        } else if (selectedGem.type === GEM_2STARS) {
            resonance = 30 * rank
        } else if (selectedGem.type === GEM_5STARS) {
            // 2 stars
            if (stars === 2 && rank === 1) {
                resonance = 30
            }
            if (stars === 2 && rank === 2) {
                resonance = 110
            }
            if (stars === 2 && rank === 3) {
                resonance = 190
            }
            if (stars === 2 && rank > 3) {
                resonance = 190 + (90 * (rank - 3))
            }
            // 3 stars
            if (stars === 3 && rank === 1) {
                resonance = 60
            }
            if (stars === 3 && rank === 2) {
                resonance = 140
            }
            if (stars === 3 && rank > 2) {
                resonance = 140 + (90 * (rank - 2))
            }
            // 4 stars
            if (stars === 4) {
                resonance = 90 * rank
            }
            // 5 stars
            if (stars === 5) {
                resonance = 100 * rank
            }
        }
        const awakeningResonance = Object.values(gemState.awakening).reduce((s, a) => s + a.resonance, 0);
        return resonance + awakeningResonance
    }

    const getCr = (rank) => {
        let cr = 0;
        if (selectedGem.type === GEM_1STAR) {
            cr = 4 + (4 * rank)
        } else if (selectedGem.type === GEM_2STARS) {
            cr = 6 + (6 * rank)
        } else if (selectedGem.type === GEM_5STARS) {
            // 2 stars
            if (stars === 2 && rank === 1) {
                cr = 12
            }
            if (stars === 2 && rank === 2) {
                cr = 24
            }
            if (stars === 2 && rank === 3) {
                cr = 44
            }
            if (stars === 2 && rank > 3) {
                cr = 44 + (22 * (rank - 3))
            }
            // 3 stars
            if (stars === 3 && rank === 1) {
                cr = 18
            }
            if (stars === 3 && rank === 2) {
                cr = 36
            }
            if (stars === 3 && rank === 3) {
                cr = 56
            }
            if (stars === 3 && rank > 3) {
                cr = 56 + (22 * (rank - 3))
            }
            // 4 stars
            if (stars === 4) {
                cr = 22 * rank
            }
            // 5 stars
            if (stars === 5) {
                cr = 24 * rank
            }
        }
        return cr
    }

    const getGemHtml = (rank) => {
        let html = ''
        const gemBuffValues = selectedGem.buffValues[`lvl${rank}`]

        if (!gemBuffValues) return t('legendaryGems.noInfo')

        if (rank >= 3) {
            if(selectedGem.i18n === 'echoShade' && rank>=5){
                // Hardcode for Echo Shade because it adds additional text to buff2 from rank 5
                html = t(`${i18nPrefix}.template3`, {
                    "buff1": t(`${i18nPrefix}.buff1`, selectedGem.buffValues[`lvl${rank}`]?.buff1),
                    "buff2": t(`${i18nPrefix}.buff2`, selectedGem.buffValues[`lvl${rank}`]?.buff2),
                    "buff3": t(`${i18nPrefix}.buff3`, selectedGem.buffValues[`lvl${rank}`]?.buff3)
                })
            }else{
                html = t(`${i18nPrefix}.template2`, {
                    "buff1": t(`${i18nPrefix}.buff1`, selectedGem.buffValues[`lvl${rank}`]?.buff1),
                    "buff2": t(`${i18nPrefix}.buff2`, selectedGem.buffValues[`lvl${rank}`]?.buff2),
                })
            }
        }else{
            html = t(`${i18nPrefix}.template1`, {
                "buff1": t(`${i18nPrefix}.buff1`, selectedGem.buffValues[`lvl${rank}`]?.buff1)
            })
        }

        return html
    }

    const getMf = () => {
        return 5 * selectedGem.type;
    }

    return (
        <div className="gem-container" style={{maxWidth: 280, position: 'relative', margin: '0 auto'}}
             data-inventory-color="6" data-core-details="true" data-rank="true">
            <div className="gem-background legendary"/>
            <div className="gem-content">
                <div className="gem-name">
                    <p>{t(`${i18nPrefix}.name`)}</p>
                    <p>({showDiff &&
                            <>{t('rank')} {props.fromRank}<ArrowIcon/></>
                        } {t('rank')} {props.toRank})</p>
                </div>
                <div className="gem-subheader legendary">
                    <div className="gem-icon-bg legendary">
                        <div className="gem-icon legendary"
                             style={{backgroundImage: `url("/static/images/legendaryGems/${selectedGem.id}.webp")`}}/>
                    </div>
                    <div className="gem-type">
                        {t('gemTypes.legendary')}
                    </div>
                    <div className="lgem-stars">
                        {/* <StarsInfo/> */}
                        {gemState.selectedGem && gemState.selectedGem.type === GEM_5STARS ?
                            <Grid item>
                                <Rating
                                    name="simple-controlled"
                                    icon={<Star active/>}
                                    emptyIcon={<Star/>}
                                    value={stars}
                                    onChange={(event, newValue) => {
                                        if (newValue < 2) return;
                                        setStars(newValue);
                                    }}
                                />
                            </Grid>
                            :
                            <StarsInfo/>
                        }
                    </div>
                </div>
                <div className="gem-info legendary">
                    <FormControlLabel
                        value="end"
                        control={<Checkbox sx={{
                            color: '#b87157',
                            '&.Mui-checked': {
                                color: '#b87157',
                            }
                        }} value={showDiff} onChange={e => setShowDiff(e.target.checked)}/>}
                        label={t("legendaryGems.showDifference")}
                        labelPlacement="end"
                    />
                    <ul>
                        <li>
                            <span className="whtt-innate-text">
                                <span>{t("legendaryGems.resonance")} </span>
                                {showDiff && <><b>{getResonance(props.fromRank)} </b><ArrowIcon type={'right'}/></>}
                                <b>{getResonance(props.toRank)} </b>
                            </span>
                        </li>
                        <li>
                            <span className="whtt-innate-text">
                                {showDiff && <><b>+{getCr(props.fromRank)} </b><ArrowIcon type={'right'}/></>}
                                <b>+{getCr(props.toRank)} </b>
                                <span>{t("legendaryGems.cr")}</span>
                            </span>
                        </li>
                        <li>
                            {showDiff &&
                                <>
                                    <span dangerouslySetInnerHTML={{__html: getGemHtml(props.fromRank)}}/>
                                    <div className={'text-center'}>
                                        <ArrowIcon type={'down'}/>
                                    </div>
                                </>
                            }
                            <span dangerouslySetInnerHTML={{__html: getGemHtml(props.toRank)}}/>
                        </li>
                        {props.toRank >= 5 &&
                            <li>
                                <span> {t('legendaryGems.mf')} <b>{getMf()}%</b></span>
                            </li>
                        }
                    </ul>

                    <Awakening />

                </div>
            </div>
        </div>
    );
};

LegendaryGem.propTypes = {
    toRank: PropTypes.number.isRequired,
    fromRank: PropTypes.number,
    // stars: PropTypes.oneOfType([PropTypes.number, PropTypes.bool])
};

export default LegendaryGem;