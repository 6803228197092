import React from 'react';
import {useTranslation} from "react-i18next";
import PropTypes from 'prop-types';
import {
    FormControl,
    InputLabel,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select
} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import LegendaryGemIcon from "../LegendaryGemIcon";
import {getLegendaryGem} from "../../../utils/items";

const RANKS = Array.from({length: 10}, (_, i) => i + 1)
const AwakeningGem = props => {
    const {t} = useTranslation();
    const gem = getLegendaryGem(props.gemId)
    const theme = useTheme();
    const handleChange = (e) =>{
        const rank = e.target.value;
        props.onChange(
            props.gemId,
            rank,
            rank * getMultiplier(props.type),
            props.type
        )
    }

    const getMultiplier = (type) =>{
        switch (type) {
            case "1starGemIds":
                return 1;
            case "2starGemIds":
                return 2;
            case "5starGemIds":
                return 10;
            default:
                return 1;
        }
    }

    return (
        <ListItem key={props.gemId} disablePadding>
            <ListItemIcon>
                <LegendaryGemIcon id={props.gemId}/>
            </ListItemIcon>
            <ListItemText primary={t(`legendaryGems.${gem.i18n}.name`)}/>
            <FormControl sx={{ minWidth: 80 }} size='small'>
                <InputLabel id="rank-label">{t('rank')}</InputLabel>
                <Select
                    labelId="rank-label"
                    id={`rank-${props.gemId}`}
                    value={props.rank || ''}
                    onChange={handleChange}
                    MenuProps={{
                        MenuListProps: {
                            style:{background: theme.palette.background.DI}
                        }
                    }}
                    label={t('rank')}
                >
                    <MenuItem value="">
                        {t('none')}
                    </MenuItem>
                    {RANKS.map(rank => <MenuItem key={rank} value={rank}>{rank}</MenuItem>)}
                </Select>
            </FormControl>
        </ListItem>
    )
};

AwakeningGem.propTypes = {};

export default AwakeningGem;