import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";
import {Accordion, AccordionDetails, Box, Grid, Tooltip, Typography} from "@mui/material";
import {styled, useTheme} from "@mui/material/styles";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import {useStoreState} from "../../store";
import useResponsive from "../../hooks/useResponsive";
import GemIcon from "./GemIcon";

const CustomExpandIcon = () => (
    <Box
        sx={{
            '.Mui-expanded & > .collapseIconWrapper': {
                display: 'none',
            },
            '.expandIconWrapper': {
                display: 'none',
            },
            '.Mui-expanded & > .expandIconWrapper': {
                display: 'block',
            },
        }}
    >
        <div style={{marginLeft: -10}} className="expandIconWrapper">
            <Typography variant={'h3'}>-</Typography>
        </div>
        <div style={{marginRight: -10}} className="collapseIconWrapper">
            <Typography variant={'h4'}>+</Typography>
        </div>
    </Box>
)


const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<CustomExpandIcon/>}
        {...props}
    />
))(() => ({
    backgroundColor: 'rgba(255, 255, 255, .05)'
}));

const GemUpgradeTable = props => {
    const RANKS = Array.from({length: props.lvl - 1}, (_, i) => i + 1)
    const {t} = useTranslation();
    const gemState = useStoreState('gems')
    const theme = useTheme()
    const mdDown = useResponsive('down', 'md');

    return (
        <div>
            <Accordion sx={{
                backgroundColor: theme.palette.background.DI,
            }}>
                <AccordionSummary
                    aria-controls={`total`}
                    id={`total`}
                    sx={{
                        backgroundColor: theme.palette.background.DI,
                        borderRadius: 1,
                        paddingLeft: mdDown ? 0 : 3
                    }}
                >
                    <Grid container>
                        <Grid item md={5} sm={5} xs={5}/>
                        <Grid item md={2} sm={2} xs={2}>
                            <div style={{position: 'relative'}}>
                                <GemIcon gem={gemState.selectedGem} rank={1} />
                                <div className={'quantity gem'}>: {3 ** (props.lvl - 1)}</div>
                            </div>
                        </Grid>
                        <Grid item md={5} sm={5} xs={5}/>
                    </Grid>
                </AccordionSummary>
                {props.lvl !== 1 &&
                    <AccordionDetails sx={{
                        backgroundColor: theme.palette.background.DI,
                        borderTop: `1px solid ${theme.palette.grey[700]}`,
                    }}>
                        <Typography variant={"h4"} style={{padding: '15px 0'}}>{`${t('rank')} ${props.lvl} ${t("otherGemsHeader")}`}:</Typography>
                        <Grid container spacing={2}>
                            {RANKS.map((rank, i) =>
                                <Grid key={i} item md={4} sm={4} xs={4}>
                                    <div style={{position: 'relative'}}>
                                        <Tooltip enterTouchDelay={0} followCursor title={`${t('rank')} ${rank}`}>
                                            <span>
                                                <GemIcon gem={gemState.selectedGem} rank={rank} />
                                            </span>
                                        </Tooltip>
                                        <div className={'quantity gem'}> : {3 ** (props.lvl - rank)}</div>
                                    </div>
                                </Grid>
                            )}
                        </Grid>
                    </AccordionDetails>
                }
            </Accordion>
        </div>
    );
};

GemUpgradeTable.propTypes = {
    lvl: PropTypes.number.isRequired
};

export default GemUpgradeTable;