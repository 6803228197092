import {
    DUNGEON_SELECT,
    DUNGEON_DESELECT, CLEAR_SELECTED_ITEMS, CLEAR_SELECTED_DUNGEONS,
} from "../constants";

export default (state, action) => {
    switch (action.type) {
        case DUNGEON_SELECT:
            return {...state, selectedDungeons: [...state.selectedDungeons, action.payload]};
        case DUNGEON_DESELECT:
            return {...state, selectedDungeons: state.selectedDungeons.filter((item) => item !== action.payload)};
        case CLEAR_SELECTED_DUNGEONS:
            return {...state, selectedDungeons: []};
        default:
            return state;
    }
};