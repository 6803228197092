import React from 'react';
import PropTypes from 'prop-types';
import {ImageListItem} from "@mui/material";
import Star from "./Star";

const Stars = props => {
    const stars = [];
    for (let i = 0; i < props.activeNum; i += 1) {
        stars.push(<ImageListItem key={i}><Star active/></ImageListItem>)
    }
    if(props.unknownNum !== 0){
        for (let i = 0; i < props.unknownNum; i += 1) {
            stars.push(<ImageListItem key={`star-${i}`}><Star unknown/></ImageListItem>)
        }
    }else{
        for (let i = 0; i < (props.amount - props.activeNum); i += 1) {
            stars.push(<ImageListItem key={`star-${i}`}><Star/></ImageListItem>)
        }
    }
    return (
        stars
    );
};

Stars.propTypes = {
    amount: PropTypes.number,
    activeNum: PropTypes.number,
    unknownNum: PropTypes.number
};

export default Stars;