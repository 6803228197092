import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";
import {List, ListItem, ListItemIcon, ListItemText} from "@mui/material";
import {GEM_1STAR, GEM_2STARS, GEM_5STARS, IMAGE_PATH} from "../../../constants";
import {useStoreState} from "../../../store";
import LegendaryGemIcon from "../LegendaryGemIcon";

const AwakeningBuffs = props => {
    const {t} = useTranslation();
    const gemState = useStoreState('legendaryGems');
    const {selectedGem} = gemState;

    const awakeningResonance = Object.values(gemState.awakening).reduce((s, a) => s + a.resonance, 0);

    // console.log(selectedGem, selectedGem.awakeningBuffsType)
    const AwakeningBuffIcon = ({rank}) => {
        return <ListItemIcon className={'resonance-icon'}>
            <img alt={'aw-res'} src={`${IMAGE_PATH}/aw-res-bg.png`}/>
            <span className={`resonance-icon-text ${rank > 100 ? 'small' : ''}`}>{rank}</span>
        </ListItemIcon>
    }

    const AwakeningBuffText = ({type}) => {
        return <ListItemText primary={
            selectedGem.awakeningBuffsType ?
                t(`awakening.${selectedGem.awakeningBuffsType}.${type}`) :
                t(`awakening.noInfo`)
        }/>
    }

    return (
        <List>
            {selectedGem.type === GEM_1STAR &&
                <>
                    <ListItem className={`res10 ${awakeningResonance < 10 ? 'not-active' : ''}`}>
                        <AwakeningBuffIcon rank={10}/>
                        <AwakeningBuffText type={1}/>
                    </ListItem>
                    <ListItem className={`res20 ${awakeningResonance < 20 ? 'not-active' : ''}`}>
                        <AwakeningBuffIcon rank={20}/>
                        <AwakeningBuffText type={2}/>
                    </ListItem>
                </>
            }
            {selectedGem.type === GEM_2STARS &&
                <>
                    <ListItem className={`res10 ${awakeningResonance < 10 ? 'not-active' : ''}`}>
                        <AwakeningBuffIcon rank={10}/>
                        <AwakeningBuffText type={1}/>
                    </ListItem>
                    <ListItem className={`res30 ${awakeningResonance < 30 ? 'not-active' : ''}`}>
                        <AwakeningBuffIcon rank={30}/>
                        <AwakeningBuffText type={2}/>
                    </ListItem>
                    <ListItem className={`res50 ${awakeningResonance < 50 ? 'not-active' : ''}`}>
                        <AwakeningBuffIcon rank={50}/>
                        <AwakeningBuffText type={3}/>
                    </ListItem>
                </>
            }

            {selectedGem.type === GEM_5STARS &&
                <>
                    <ListItem className={`res20 ${awakeningResonance < 20 ? 'not-active' : ''}`}>
                        <AwakeningBuffIcon rank={20}/>
                        <AwakeningBuffText type={1}/>
                    </ListItem>
                    <ListItem className={`res40 ${awakeningResonance < 40 ? 'not-active' : ''}`}>
                        <AwakeningBuffIcon rank={40}/>
                        <AwakeningBuffText type={2}/>
                    </ListItem>
                    <ListItem className={`res60 ${awakeningResonance < 60 ? 'not-active' : ''}`}>
                        <AwakeningBuffIcon rank={60}/>
                        <AwakeningBuffText type={3}/>
                    </ListItem>
                    <ListItem className={`res160 ${awakeningResonance < 160 ? 'not-active' : ''}`}>
                        <AwakeningBuffIcon rank={160}/>
                        <AwakeningBuffText type={4}/>
                    </ListItem>
                    <ListItem className={`res260 ${awakeningResonance < 260 ? 'not-active' : ''}`}>
                        <AwakeningBuffIcon rank={260}/>
                        <AwakeningBuffText type={5}/>
                    </ListItem>
                </>
            }
        </List>
    );
};

AwakeningBuffs.propTypes = {

};

export default AwakeningBuffs;