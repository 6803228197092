import React from 'react';

function Star(props) {
    const starState = () => {
        let state = ''
        if(props.active){
            state = '_active'
        }else if (props.unknown){
            state = '_unknown'
        }
        return state;
    }
    return (
        <span>
            <img width={props.width || 20} src={`/static/images/legendaryGems/star${starState()}.webp`} alt={'star'}/>
        </span>
    );
}

export default Star;