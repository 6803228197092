import React from 'react';
import PropTypes from 'prop-types';
import {Typography} from "@mui/material";
import {alpha, styled} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";
import Iconify from "../Iconify";
import {SET_ITEMS_INFO} from "../../constants";
import {useStoreState} from "../../store";

const ItemTooltip = props => {
    const {t} = useTranslation();
    const setsState = useStoreState('sets');

    const HtmlTooltip = styled(({className, ...props}) => (
        <Tooltip {...props} classes={{popper: className}}/>
    ))(({theme}) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            // background: "url('/static/images/tooltip-bg-spacer.png')",
            background: "none",
            // color: '#15a103',
            color: '#525151',
            // width: 850,
            backgroundSize: '100%',
            // padding: '1em 2em',
            fontSize: theme.typography.pxToRem(16),
            // border: '1px solid #c2c2c2',
            // boxShadow: '1px 1px 2px 1px rgba(0,0,0,0.4)'
        },
    }));

    // console.log(props.item, SET_ITEMS_INFO)
    const setInfo = SET_ITEMS_INFO.find(setInfo => setInfo.setId === props.item.setId);
    const filteredSetItems = setsState.selectedItems.filter(selectedItem => selectedItem.setId === props.item.setId);

    const IconWrapperStyle = styled('div')(() => ({
        margin: 'auto',
        display: 'flex',
        borderRadius: '50%',
        alignItems: 'center',
        justifyContent: 'center',
    }));
    return (
        <HtmlTooltip
            title={
                <>
                    <div className="gem-container" style={{maxWidth: 280, position: 'relative', margin: '0 auto'}}
                         data-inventory-color="6" data-core-details="true" data-rank="true">
                        <div className="gem-background setItem"/>
                        <div className="gem-content" style={{padding: '2em'}}>
                            <div className="gem-name" style={{marginBottom: 20}}>
                                <Typography align={'center'} variant="h6" className={'di-text item-name'} color="inherit">{t(props.item.name)}</Typography>
                            </div>
                            {setInfo &&
                                <ul className={'item-info list'}>
                                    {setInfo.info.map((infoRow, i)=>
                                        <li key={i}
                                            className={(infoRow.itemsCount <= filteredSetItems.length ? 'item-info-active':'')}
                                            // eslint-disable-next-line
                                            dangerouslySetInnerHTML={{__html:t(infoRow.text)}}/>
                                    )}
                                </ul>
                            }
                        </div>
                    </div>
                    {/* <Typography align={'center'} variant="h6" className={'di-text item-name'} color="inherit">{t(props.item.name)}</Typography> */}
                    {/* <hr className={'tooltip-name-divider'}/> */}
                    {/* {setInfo && */}
                    {/*    <ul className={'item-info list'}> */}
                    {/*        {setInfo.info.map((infoRow, i)=> */}
                    {/*            <li key={i} */}
                    {/*                className={(infoRow.itemsCount <= filteredSetItems.length ? 'item-info-active':'')} */}
                    {/*                dangerouslySetInnerHTML={{__html:t(infoRow.text)}}/> */}
                    {/*        )} */}
                    {/*    </ul> */}
                    {/* } */}
                </>
            }
        >
            <IconWrapperStyle
                style={{
                    position:'absolute',
                    top: 0,
                    left: 0,
                    zIndex:2
                }}
                sx={{
                    color: (theme) => theme.palette.info.dark,
                    backgroundImage: (theme) =>
                        `linear-gradient(135deg, ${alpha(theme.palette.info.dark, 0)} 0%, ${alpha(
                            theme.palette.info.dark,
                            0.24
                        )} 100%)`,
                }}
            >
                <Iconify icon={'ant-design:info-circle-filled'} width={25} height={25} />
            </IconWrapperStyle>
        </HtmlTooltip>
    );
};

ItemTooltip.propTypes = {
    item: PropTypes.object
};

export default ItemTooltip;