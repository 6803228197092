import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";
import {ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import LegendaryGemIcon from "./LegendaryGemIcon";
import {dispatch, useStoreState} from "../../store";
import {LEGENDARY_GEM_SELECT} from "../../constants";
import CircularProgressWithLabel from "./CircularProgressWithLabel";

const LegendaryGemButton = ({gem, onSelect, isLast}) => {
    const {t} = useTranslation();
    const gemState = useStoreState('legendaryGems');
    const selectGem = (id) =>{
        dispatch({
            'type': LEGENDARY_GEM_SELECT, 'payload': id
        })
        if(onSelect){
            onSelect()
        }
    }

    const buffValues = Object.keys(gem.buffValues);
    const isCompleted = buffValues.length === 10;
    const percentage = (buffValues.length / 10) * 100;

    return (
        <ListItem key={gem.id} disablePadding>
            <ListItemButton style={{border: '1px solid #343434', marginBottom: isLast ? 0 : 5}}
                            selected={gem.id === gemState.selectedGem?.id}
                            onClick={()=>selectGem(gem.id)}>
                <ListItemIcon>
                    <LegendaryGemIcon id={gem.id}/>
                </ListItemIcon>
                <ListItemText primary={t(`legendaryGems.${gem.i18n}.name`)}/>
                {!isCompleted && <CircularProgressWithLabel size={'3em'} variant="determinate" value={percentage} /> }
            </ListItemButton>
        </ListItem>
    );
};

LegendaryGemButton.propTypes = {
    gem: PropTypes.object.isRequired
};

export default LegendaryGemButton;