import React from 'react';
import {useTheme} from '@mui/material/styles';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";
import {Accordion, AccordionDetails, Box, Grid, Typography} from "@mui/material";
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import LegendaryGemIcon from "./LegendaryGemIcon";
import {useStoreState} from "../../store";
import GemPowerIcon from "./GemPowerIcon";
import {
    GEM_1STAR,
    GEM_2STARS,
    GEM_5STARS,
    UPDATE_TABLE_1STAR,
    UPDATE_TABLE_2STARS,
    UPDATE_TABLE_5STARS
} from "../../constants";
import ArrowIcon from "../ArrowIcon";
import GemPowerQuantity from "./GemPowerQuantity";
import GemQuantity from "./GemQuantity";
import LegendaryGemUpgradeTotal from "./LegendaryGemUpgradeTotal";
import {getLegendaryGem} from "../../utils/items";
import CustomExpandIcon from "./CustomExpandIcon";

const LegendaryGemUpgradeTable = props => {
    const {t} = useTranslation();
    const gemState = useStoreState('legendaryGems')
    const theme = useTheme()

    let data = []
    if (gemState.selectedGem.type === GEM_1STAR) {
        data = UPDATE_TABLE_1STAR.filter(obj => obj.rank > props.fromRank && obj.rank <= props.toRank)
    } else if (gemState.selectedGem.type === GEM_2STARS) {
        data = UPDATE_TABLE_2STARS.filter(obj => obj.rank > props.fromRank && obj.rank <= props.toRank)
    } else if (gemState.selectedGem.type === GEM_5STARS) {
        data = UPDATE_TABLE_5STARS.filter(obj => obj.rank > props.fromRank && obj.rank <= props.toRank)
    }

    return (
        <Box>
            <Typography variant="h4" gutterBottom>
                {t('rankTableHeader')}
            </Typography>

            <div className={`di-gem-image`} style={{position: 'relative', margin: '0 auto'}}>
                <LegendaryGemIcon id={gemState.selectedGem.id}/>
                <span className={'gem-rank-label'}>{t('rank')} {props.fromRank}</span>
            </div>

            <div>
                <Typography variant="h4">+</Typography>
                {data.map(rankInfo => {
                    const content = []
                    if (rankInfo.gemPower > 0) {
                        content.push(<GemPowerIcon/>)
                    }
                    return <Accordion key={rankInfo.rank} sx={{
                                backgroundColor: theme.palette.background.DI,
                            }}>
                            <MuiAccordionSummary
                                expandIcon={<CustomExpandIcon/>}
                                aria-controls={`rankInfo${rankInfo.rank}-content`}
                                id={`rankInfo${rankInfo.rank}`}
                                sx={{
                                    backgroundColor: theme.palette.background.DI,
                                    borderRadius: 1
                                }}
                            >
                                <Grid container spacing={2}>
                                    <Grid item md={3} sm={3} style={{paddingTop: 30}}>
                                        {t('rank')} {rankInfo.rank}:
                                    </Grid>
                                    {rankInfo.gemPower > 0 &&
                                        <Grid item md={3} sm={3} xs={3}>
                                            <GemPowerQuantity quantity={rankInfo.gemPower}/>
                                        </Grid>
                                    }
                                    {rankInfo.gemRank1 > 0 &&
                                        <Grid item md={3} sm={3} xs={3}>
                                            <GemQuantity gemId={gemState.selectedGem.id} quantity={rankInfo.gemRank1} rank={1}/>
                                        </Grid>
                                    }
                                    {rankInfo.gemRank3 > 0 &&
                                        <Grid item md={3} sm={3} xs={3}>
                                            <GemQuantity gemId={gemState.selectedGem.id} quantity={rankInfo.gemRank3} rank={3}/>
                                        </Grid>
                                    }
                                    {rankInfo.gemRank5 > 0 &&
                                        <Grid item md={3} sm={3} xs={3}>
                                            <GemQuantity gemId={gemState.selectedGem.id} quantity={rankInfo.gemRank5} rank={5}/>
                                        </Grid>
                                    }
                                </Grid>
                            </MuiAccordionSummary>
                            <AccordionDetails sx={{
                                backgroundColor: theme.palette.background.DI,
                                borderTop: `1px solid ${theme.palette.grey[700]}`,
                            }}>
                                <Grid container spacing={2}>
                                    <Grid item md={3} sm={3} xs={3} style={{paddingTop: 30}}>
                                        <Typography variant={'h5'}>
                                            {rankInfo.rank - 1}
                                            <ArrowIcon type={'right'}/> {rankInfo.rank}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={4} sm={4} xs={4}>
                                        <GemPowerQuantity quantity={rankInfo.upgradeGemPower}/>
                                    </Grid>
                                    {rankInfo.upgradeRank1Gems > 0 &&
                                        <Grid item md={4} sm={4} xs={4}>
                                            <GemQuantity gemId={gemState.selectedGem.id} quantity={rankInfo.upgradeRank1Gems} rank={1}/>
                                        </Grid>
                                    }
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    }
                )}
                <Typography variant="h4" style={{padding: '15px 0'}}>{t("total")}:</Typography>
                <LegendaryGemUpgradeTotal gemId={gemState.selectedGem.id} fromRank={props.fromRank} toRank={props.toRank}/>
                {
                    Object.keys(gemState.awakening).length ?
                        <>
                            <Typography variant="h4" style={{padding: '15px 0'}}>{t('awakening.totalTitle')}:</Typography>
                            {
                                Object.keys(gemState.awakening).map(key => {
                                    const awakeningData = gemState.awakening[key];
                                    return <div key={`w-${key}`}>
                                            <Typography key={`label-${key}`} variant="h4" style={{padding: '15px 0'}}>{t(`legendaryGems.${getLegendaryGem(parseInt(key, 10)).i18n}.name`)} ({t('rank')} {awakeningData.rank})</Typography>
                                            <LegendaryGemUpgradeTotal key={`total-${key}`} gemId={parseInt(key, 10)} fromRank={1} toRank={awakeningData.rank}/>
                                        </div>
                                })
                            }
                        </> : ''
                }
            </div>
        </Box>
    );
};

LegendaryGemUpgradeTable.propTypes = {
    toRank: PropTypes.number,
    fromRank: PropTypes.number,
};

export default LegendaryGemUpgradeTable;