import React from 'react';
import {IconButton, Input, InputAdornment} from "@mui/material";
import Iconify from "../Iconify";
import {dispatch, useStoreState} from "../../store";
import {LEGENDARY_GEM_SEARCH} from "../../constants";

const LegendaryGemSearch = () => {

    const gemState = useStoreState('legendaryGems');

    const onSearch = (e) =>{
        dispatch({
            'type': LEGENDARY_GEM_SEARCH, 'payload': e.target.value
        })
    }

    const clearSearch = () =>{
        dispatch({
            'type': LEGENDARY_GEM_SEARCH, 'payload': ''
        })
    }

    return (
        <Input
            autoFocus
            disableUnderline
            placeholder="Search…"
            onChange={onSearch}
            value={gemState.searchValue}
            startAdornment={
                <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
            }
            endAdornment={
                <InputAdornment position="end">
                    {gemState.searchValue && <IconButton onClick={clearSearch}>
                        <Iconify icon="bi:x" sx={{ color: 'red', width: 20, height: 20 }} />
                    </IconButton>}
                </InputAdornment>
            }
            sx={{ ml: 3, border: 1, borderRadius: 1, fontWeight: 'fontWeightBold' }}
        />
    );
};

LegendaryGemSearch.propTypes = {

};

export default LegendaryGemSearch;