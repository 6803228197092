import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Grid, Typography,
} from "@mui/material";
import SetItem from "../../../components/items/SetItem";
import {SET_ITEM_ADD, SET_ITEM_REMOVE, SET_ITEMS, SETS} from "../../../constants";
import {dispatch, useStoreState} from "../../../store";

const SetItemsList = ({setId}) => {
    const {t} = useTranslation();
    const setsState = useStoreState('sets');
    const set = SETS.find(set => set.id === setId);

    const filteredSetItems = SET_ITEMS.filter(item=>item.setId === setId)

    const isSelectedItem = (itemId) => {
        return setsState.selectedItems.findIndex(item => item.id === itemId) !== -1;
    }

    const selectAll = () =>{
        filteredSetItems.forEach((item)=>{
            selectItem(item.id, true)
        })
    }

    const deselectAll = () =>{
        filteredSetItems.forEach((item)=>{
            dispatch({
                'type': SET_ITEM_REMOVE, 'payload': item.id
            })
        })
    }

    const isAllSelected = () =>{
        const selectedBySet = setsState.selectedItems.filter(item => item.setId === setId);
        return selectedBySet.length === 6;
    }

    const selectItem = (id, isAll) => {
        if (isSelectedItem(id)) {
            if(isAll)return;
            dispatch({
                'type': SET_ITEM_REMOVE, 'payload': id
            })
        } else {
            dispatch({
                'type': SET_ITEM_ADD, 'payload': id
            })
        }
    }


    return (
        <Grid item xs={12} md={4} sm={6}>
            <Card elevation={2}>
                <CardHeader disableTypography title={
                    <Typography variant='h6' noWrap>{t(set.name)}</Typography>
                } action={
                    <Button onClick={isAllSelected() ? deselectAll : selectAll}>{isAllSelected() ? t('deselectAll') : t('selectAll')}</Button>
                }/>
                <CardContent>
                    <Grid container spacing={2}>
                        {filteredSetItems.map(item => (
                            <Grid align={'center'} onClick={() => selectItem(item.id)} key={item.id} item xs={4} sm={6} md={4}>
                                <SetItem selected={isSelectedItem(item.id)} id={item.id}/>
                            </Grid>
                        ))}
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
};

SetItemsList.propTypes = {
    setId: PropTypes.number.isRequired
};

export default SetItemsList;