import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";
import {Box, Input, List, MenuItem, Select} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {GEMS, LEGENDARY_GEMS} from "../../constants";
import LegendaryGemButton from "./LegendaryGemButton";
import useResponsive from "../../hooks/useResponsive";
import {useStoreState} from "../../store";


const LegendaryGemsList = props => {
    const {t} = useTranslation();
    const gems = LEGENDARY_GEMS.filter(gem => gem.type === props.type)
    const gemState = useStoreState('legendaryGems');
    const [open, setOpen] = React.useState(false);

    const theme = useTheme()
    const mdDown = useResponsive('down', 'md');

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <Box sx={{width: '100%'}}>
            <nav>
                {mdDown ?
                    <Select
                        labelId="small-screen-gem-select"
                        id="small-screen-gem-select"
                        style={{width: '100%',marginBottom: 20}}
                        open={open}
                        value={gems[0].id || ''}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        renderValue={(selectedGem)=> {
                            if(gemState.selectedGem) {
                                return t(`legendaryGems.${gemState.selectedGem.i18n}.name`)
                            }
                            return t('selectGem')
                        }}
                        MenuProps={{
                            MenuListProps: {
                                style:{background: theme.palette.background.DI}
                            }
                        }}
                        input={<Input placeholder={"test"}/>}
                    >
                        {gems.map((gem, i) => {
                            return <LegendaryGemButton value={gem.id} key={gem.id} gem={gem}
                                                       isLast={i + 1 === gems.length}
                                                       onSelect={e => setOpen(false)}/>
                        })}
                    </Select>
                    :
                    <List>
                        {gems.map(gem => {
                            return <LegendaryGemButton key={gem.id} gem={gem}/>
                        })}
                    </List>
                }
            </nav>
        </Box>
    );
};

LegendaryGemsList.propTypes = {
    type: PropTypes.number.isRequired
};

export default LegendaryGemsList;