import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import EN from './locale/en.json';
import RU from './locale/ru.json';
import ES from './locale/es.json';
import PTBR from './locale/ptbr.json';
import KO from './locale/ko.json';
import PL from './locale/pl.json';
import DE from './locale/de.json';
import {getLang} from "./services/storageService";

const resources = {
    en: { translation: EN },
    ru: { translation: RU },
    es: { translation: ES },
    ptbr: { translation: PTBR },
    ko: { translation: KO },
    pl: { translation: PL },
    de: { translation: DE },
    // ua: { translation: UA },
};

// const lang = getLang();

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: getLang(),

        // debug: true,
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;