import React from 'react';
import PropTypes from 'prop-types';
import Iconify from "./Iconify";

const ArrowIcon = props => {
    const type = props.type || 'right';
    return (
        <Iconify style={{verticalAlign:'text-top'}} icon={`noto-v1:${type}-arrow`}/>
    );
};

ArrowIcon.propTypes = {
    type: PropTypes.string
};

export default ArrowIcon;