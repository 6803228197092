import React from 'react';
import PropTypes from 'prop-types';
import {IMAGE_PATH} from "../../constants";

const GemIcon = props => (
    <div>
        <img src={`${IMAGE_PATH}/gems/${props.gem.id}/${props.rank ? props.rank : 1}.webp`}
             className={`di-gem-image`}
             alt={props.gem.name}
            // style={{borderRadius: '5%'}}
             onError={({currentTarget}) => {
                 currentTarget.onerror = null; // prevents looping
                 currentTarget.src = "/static/images/placeholder.png";
             }}
        />
    </div>
);

GemIcon.propTypes = {
    gem: PropTypes.object.isRequired,
    rank: PropTypes.number
};

export default GemIcon;