import React from 'react';
import {Box, Typography} from "@mui/material";

const CustomExpandIcon = () => {
    return (
        <Box
            sx={{
                '.Mui-expanded & > .collapseIconWrapper': {
                    display: 'none',
                },
                '.expandIconWrapper': {
                    display: 'none',
                },
                '.Mui-expanded & > .expandIconWrapper': {
                    display: 'block',
                },
            }}
        >
            <div style={{marginLeft: -10}} className="expandIconWrapper">
                <Typography variant='h3'>-</Typography>
            </div>
            <div style={{marginRight: -10}} className="collapseIconWrapper">
                <Typography variant='h4'>+</Typography>
            </div>
        </Box>
    );
};

export default CustomExpandIcon;