import React from 'react';
import PropTypes from 'prop-types';
import {Container, Grid, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import Page from "../../components/Page";
import useResponsive from "../../hooks/useResponsive";
import GemsList from "../../components/gems/GemsList";
import GemConfigurator from "../../components/gems/GemConfigurator";

const Gems = props => {
    const {t} = useTranslation();
    const mdDown = useResponsive('down', 'md');

    return (
        <Page title="Gems">
            <Container maxWidth="xl">
                <Typography variant={'h3'} sx={{marginBottom: 3}}>
                    {t("pageTitle.gems")}
                </Typography>
                <Grid container>
                    <Grid item xs={12} md={mdDown ? 12 : 3} style={{borderRight: '1px solid #343434'}}>
                        <GemsList/>
                    </Grid>
                    <Grid item xs={12} md={8} sm={12}>
                        <GemConfigurator/>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
};

Gems.propTypes = {

};

export default Gems;