import { createStore } from 'react-hooks-global-state';
import setsReducer from "../reducers/setsReducer";
import legendaryGemsReducer from "../reducers/legendaryGemsReducer";
import gemsReducer from "../reducers/gemsReducer";
import dungeonsReducer from "../reducers/dungeonsReducer";

function combineReducers(reducers) {
    /* eslint-disable */
    return (state = {}, action) => {
        const newState = {};
        /* eslint-disable */
        for (let key in reducers) {
            newState[key] = reducers[key](state[key], action);
        }
        return newState;
    };
}

const reducer = combineReducers({
    sets: setsReducer,
    legendaryGems: legendaryGemsReducer,
    gems: gemsReducer,
    dungeons: dungeonsReducer
});

const initialState = {
    sets: {
        selectedItems: []
    },
    legendaryGems: {
        selectedGem: null,
        searchValue: '',
        awakening: {}
    },
    gems: {
        selectedGem: null,
    },
    dungeons:{
        selectedDungeons: []
    },
    counter: 0
};
export const { dispatch, useStoreState } = createStore(reducer, initialState);