import React from 'react';
import {IconButton, Tooltip} from "@mui/material";
import Iconify from "../../components/Iconify";

const BuyMeACoffee = () => {
    const openLink = () =>{
        window.open('https://buymeacoffee.com/zidix', '_blank').focus()
    }
    return (
        <div>
            {/* <a href="https://www.buymeacoffee.com/zidix" target="_blank" rel='noreferrer'> */}
            {/*    <img src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png" alt="Buy Me A Coffee" */}
            {/*     style={{height:30, width:120}}/></a> */}
            <Tooltip title="Buy me a coffee">
                <IconButton color="warning" onClick={openLink}>
                    <Iconify icon="bi:cup-hot-fill" width={20} height={20}/>
                </IconButton>
            </Tooltip>
        </div>
    );
};

BuyMeACoffee.propTypes = {};

export default BuyMeACoffee;