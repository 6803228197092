import React from 'react';
import PropTypes from 'prop-types';
import {Box, Button, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {
    DUNGEON_DESELECT,
    DUNGEON_SELECT,
    DUNGEONS,
    SET_ITEM_ADD,
    SET_ITEM_REMOVE,
    SET_ITEMS
} from "../../constants";
import {dispatch, useStoreState} from "../../store";
import {checkItemInDungeon} from "../../helpers/itemsHelpers";

const DungeonsList = props => {
    const {t} = useTranslation();
    const setsState = useStoreState('sets');
    const dungeonsState = useStoreState('dungeons');

    const isSelectedItem = (itemId) => {
        return setsState.selectedItems.findIndex(item => item.id === itemId) !== -1;
    }

    const isAllSelected = (dungeonId) =>{
        const selectedBySet = setsState.selectedItems.filter(item => checkItemInDungeon(item, dungeonId));
        const allItemsByDungeon = SET_ITEMS.filter(item => checkItemInDungeon(item, dungeonId));
        return selectedBySet.length === allItemsByDungeon.length;
    }

    const selectItems = (dungeonId) =>{
        const filteredSetItems = SET_ITEMS.filter(item => checkItemInDungeon(item, dungeonId));
        if(isAllSelected(dungeonId)){

            dispatch({
                'type': DUNGEON_DESELECT, 'payload': dungeonId
            })

            const selectedDungeons = dungeonsState.selectedDungeons;

            filteredSetItems.forEach(item=>{
                if(Array.isArray(item.dropInfo)){
                    let itemInDungeonsCounter = 0
                    item.dropInfo.forEach(id=>{
                        if(selectedDungeons.indexOf(id) !== -1){
                            itemInDungeonsCounter += 1;
                        }
                    })
                    if(itemInDungeonsCounter > 1){
                        return;
                    }
                }
                dispatch({
                    'type': SET_ITEM_REMOVE, 'payload': item.id
                })
            })
        }else{

            dispatch({
                'type': DUNGEON_SELECT, 'payload': dungeonId
            })

            filteredSetItems.forEach(item=>{
                if (!isSelectedItem(item.id)) {
                    dispatch({
                        'type': SET_ITEM_ADD, 'payload': item.id
                    })
                }
            })
        }
    }

    return (
        <Box mb={3}>
            <Typography variant="h4" sx={{mb: 1}}>
                {t('selectByDungeon')}
            </Typography>
            {DUNGEONS.map(dungeon=>(
                <Button variant={isAllSelected(dungeon.id) ? "contained" : "outlined"}
                        color={'warning'}
                        key={dungeon.id}
                        style={{marginRight:5, marginBottom: 5, width: 170}}
                        onClick={()=>selectItems(dungeon.id)}>
                    <Typography noWrap>{t(dungeon.name)}</Typography>
                </Button>
            ))}
        </Box>
    );
};

DungeonsList.propTypes = {

};

export default DungeonsList;