import {
    CLEAR_SELECTED_GEM,
    GEM_SELECT,
} from "../constants";
import {getGem} from "../utils/items";

export default (state, action) => {
    switch (action.type) {
        case GEM_SELECT:
            return {...state, selectedGem: getGem(action.payload)};
        case CLEAR_SELECTED_GEM:
            return {...state, selectedGem: null};
        default:
            return state;
    }
};