import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";
import {Container, Grid, Typography} from "@mui/material";
import moment from "moment-timezone";
import Page from "../../components/Page";
import SelectedItemsList from "./components/SelectedItemsList";
import SetsList from "./components/SetsList";
import DungeonsList from "../../components/dungeons/DungeonsList";

const Sets = props => {
    const { t, i18n } = useTranslation();
    return (
        <Page title="Sets">
            <Container maxWidth="xl">
                <Typography variant="h3" sx={{mb: 2}}>{t('welcome')}</Typography>
                <SelectedItemsList/>
                <DungeonsList/>
                <SetsList/>
            </Container>
        </Page>
    );
};

Sets.propTypes = {

};

export default Sets;