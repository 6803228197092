import React from 'react';
import PropTypes from 'prop-types';
import {Grid, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import SetItemsList from "./SetItemsList";
import {SETS} from "../../../constants";

const SetsList = props => {
    const {t} = useTranslation();
    return (
        <>
            <Typography variant="h4" sx={{mb: 1}}>
                {t('selectBySet')}
            </Typography>
            <Grid container spacing={2}>
                {SETS.map(set=>{
                    return <SetItemsList key={set.id} setId={set.id}/>
                })}
            </Grid>
        </>
    );
};

SetsList.propTypes = {

};

export default SetsList;