import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";
import {ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {dispatch, useStoreState} from "../../store";
import {GEM_SELECT} from "../../constants";
import GemIcon from "./GemIcon";

const GemButton = ({gem, onSelect, isLast}) => {
    const {t} = useTranslation();
    const gemState = useStoreState('gems');
    const selectGem = (id) =>{
        dispatch({
            'type': GEM_SELECT, 'payload': id
        })
        if(onSelect){
            onSelect()
        }
    }
    return (
        <ListItem key={gem.id} disablePadding>
            <ListItemButton style={{border: '1px solid #343434', marginBottom: isLast ? 0 : 5}}
                            selected={gem.id === gemState.selectedGem?.id}
                            onClick={()=>selectGem(gem.id)}>
                <ListItemIcon>
                    <GemIcon gem={gem} rank={1}/>
                </ListItemIcon>
                <ListItemText primary={t(`gems.${gem.i18n}`)}/>
            </ListItemButton>
        </ListItem>
    );
};

GemButton.propTypes = {
    gem: PropTypes.object.isRequired,
    onSelect: PropTypes.func,
    isLast: PropTypes.bool
};

export default GemButton;