import React from 'react';
import {Box, FormControl, Grid, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import LegendaryGem from "./LegendaryGem";
import {useStoreState} from "../../store";
import LegendaryGemUpgradeTable from "./LegendaryGemUpgradeTable";
import useResponsive from "../../hooks/useResponsive";
import ArrowIcon from "../ArrowIcon";

const RANKS = Array.from({length: 10}, (_, i) => i + 1)

const LegendaryGemConfigurator = () => {
    const {t} = useTranslation();
    const [fromRank, setFromRank] = React.useState(1);
    const [toRank, setToRank] = React.useState(10);
    const gemState = useStoreState('legendaryGems');
    const theme = useTheme();
    const mdDown = useResponsive('down', 'md');

    return (
        <Box>
            <Grid
                container
                spacing={3}
                alignItems="center"
                justifyContent="center"
                style={{marginBottom: 20}}
            >
                {!gemState.selectedGem ?
                    !mdDown && <Typography variant="h3">{t('selectGem')}</Typography>
                    :
                    <>
                        <Grid item>
                            <FormControl>
                                <InputLabel id="level-select">{t('from')}</InputLabel>
                                <Select
                                    labelId="level-select"
                                    id="level-select"
                                    value={fromRank}
                                    label="Level"
                                    MenuProps={{
                                        MenuListProps: {
                                            style:{background: theme.palette.background.DI}
                                        }
                                    }}
                                    onChange={e=> setFromRank(e.target.value)}
                                >
                                    {
                                        RANKS.filter(rank => rank < toRank).map(rank => <MenuItem key={rank} value={rank}>{rank}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                            <span style={{display:'inline-block',margin:'1em'}}><ArrowIcon type={'right'}/></span>
                            <FormControl>
                                <InputLabel id="level-select">{t('to')}</InputLabel>
                                <Select
                                    labelId="level-select"
                                    id="level-select"
                                    value={toRank}
                                    label="Level"
                                    MenuProps={{
                                        MenuListProps: {
                                            style:{background: theme.palette.background.DI}
                                        }
                                    }}
                                    onChange={e=> setToRank(e.target.value)}
                                >
                                    {
                                        RANKS.filter(rank => rank > fromRank).map(rank => <MenuItem key={rank} value={rank}>{rank}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                    </>
                }
            </Grid>
            <Grid
                container
                spacing={0}
            >
                <Grid item xs={12} sm={12} md={5}
                      alignItems="center"
                      justifyContent="center">
                    <LegendaryGem fromRank={fromRank} toRank={toRank}/>
                </Grid>
                <Grid item xs={12} sm={12} md={7} textAlign="center">
                    { gemState.selectedGem && <LegendaryGemUpgradeTable fromRank={fromRank} toRank={toRank}/> }
                </Grid>
            </Grid>
        </Box>
    );
};

export default LegendaryGemConfigurator;