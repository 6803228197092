import {useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
// material
import {alpha} from '@mui/material/styles';
import {Box, MenuItem, Stack, IconButton} from '@mui/material';
// components
import MenuPopover from '../../components/MenuPopover';
import {getLang, setLang} from "../../services/storageService";

// ----------------------------------------------------------------------

const LANGS = [
    {
        value: 'en',
        label: 'English',
        icon: '/static/icons/en.svg',
    },
    // {
    //     value: 'ua',
    //     label: 'Українська',
    //     icon: '/static/icons/ua.svg',
    // },
    {
        value: 'ru',
        label: 'Русский',
        icon: '/static/icons/ru.svg',
    },
    {
        value: 'es',
        label: 'Spanish',
        icon: '/static/icons/es.svg',
    },
    {
        value: 'ko',
        label: 'Korean',
        icon: '/static/icons/ko.svg',
    },
    {
        value: 'de',
        label: 'German',
        icon: '/static/icons/de.svg',
    },
    {
        value: 'pl',
        label: 'Polish',
        icon: '/static/icons/pl.svg',
    },
    {
        value: 'ptbr',
        label: 'Portuguese (BR)',
        icon: '/static/icons/br.svg',
    },
];

// ----------------------------------------------------------------------

export default function LanguagePopover() {
    const {i18n} = useTranslation();
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const changeLanguage = (lang) => {
        setLang(lang);
        i18n.changeLanguage(lang)
        handleClose();
    }

    const getCurrentLang = () => {
        const currentLang = LANGS.find(lang=>lang.value === getLang());
        if(!currentLang){
            return 'en';
        }
        return currentLang;
    }

    return (
        <>
            <IconButton
                ref={anchorRef}
                onClick={handleOpen}
                sx={{
                    padding: 0,
                    width: 30,
                    height: 30,
                    ...(open && {
                        bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
                    }),
                }}
            >
                <img style={{borderRadius: '5px'}} src={getCurrentLang().icon} alt={getCurrentLang().label}/>
            </IconButton>

            <MenuPopover
                open={open}
                onClose={handleClose}
                anchorEl={anchorRef.current}
                sx={{
                    mt: 1.5,
                    ml: 0.75,
                    width: 180,
                    color: '#000',
                    '& .MuiMenuItem-root': {px: 1, typography: 'body2', borderRadius: 0.75},
                }}
            >
                <Stack spacing={0.75}>
                    {LANGS.map((option) => (
                        <MenuItem key={option.value} selected={option.value === i18n.language}
                                  onClick={() => changeLanguage(option.value)}>
                            <Box component="img" alt={option.label} src={option.icon}
                                 sx={{width: 28, mr: 2, borderRadius: '3px'}}/>
                            {option.label}
                        </MenuItem>
                    ))}
                </Stack>
            </MenuPopover>
        </>
    );
}
