import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";
import {Button, Card, CardContent, CardHeader, Grid, IconButton, Typography} from "@mui/material";
import SetItem from "../../../components/items/SetItem";
import {dispatch, useStoreState} from "../../../store";
import {CLEAR_SELECTED_DUNGEONS, CLEAR_SELECTED_ITEMS, DUNGEONS} from "../../../constants";
import Iconify from "../../../components/Iconify";
import {convertToRoman} from "../../../utils/formatNumber";

const SelectedItemsList = props => {
    const {t} = useTranslation();
    const setsState = useStoreState('sets');
    const dungeonsState = useStoreState('dungeons');

    const itemsByDungeon = [];
    DUNGEONS.forEach(dungeon=>{
        let add = false;
        const dItems = {
            ...dungeon,
            items: []
        };
        const selectedDungeons = dungeonsState.selectedDungeons;

        if(selectedDungeons.length){
            setsState.selectedItems.forEach(item=>{
                if(Array.isArray(item.dropInfo)){
                    if(item.dropInfo.findIndex(o=>o.dungId === dungeon.id) !== -1 && selectedDungeons.indexOf(dungeon.id) !== -1){
                        add = true;
                        dItems.items.push(item)
                    }
                }else if(item.dropInfo === dungeon.id){
                    add = true;
                    dItems.items.push(item)
                }
            })
        }else{
            setsState.selectedItems.forEach(item=>{
                if(Array.isArray(item.dropInfo)){
                    if(item.dropInfo.find(o => o.dungId === dungeon.id)){
                        add = true;
                        dItems.items.push(item)
                    }
                }else if(item.dropInfo === dungeon.id){
                    add = true;
                    dItems.items.push(item)
                }
            })
        }
        if(add){
            itemsByDungeon.push(dItems);
        }
    })

    const getDifficulty = (item, dungeonId) =>{
        // console.log(item, dungeonId)
        if(Array.isArray(item.dropInfo)){
            const dropInfo = item.dropInfo.find(o => o.dungId === dungeonId)
            if(dropInfo !== undefined){
                return `${t(dropInfo.difficulty.name)} ${convertToRoman(dropInfo.difficulty.fromLvl)}`
            }
        }
        return ''
    }

    const clear = () =>{
        dispatch({
            type: CLEAR_SELECTED_ITEMS
        });
        dispatch({
            type: CLEAR_SELECTED_DUNGEONS
        })
    }

    if(!setsState.selectedItems.length){
        return '';
    }

    return (
        <div>
            <Typography variant="h4" sx={{mb: 1}}>
                {t('selectedItems')} <IconButton color={"error"} onClick={clear}>
                <Iconify icon={'ic:baseline-clear'}/>
            </IconButton>
            </Typography>
            <Card elevation={2} sx={{mb: 3}}>
                <CardContent>
                        {itemsByDungeon.map(dungeon => (
                            <Grid container key={dungeon.id} mb={2}>
                                <Grid key={dungeon.id} item xs={12} sm={3} md={3}>
                                    <span className={'di-text dungeon-name'}>{t(dungeon.name)}</span>
                                    <img src={`/static/images/dungeons/${dungeon.id}.png`}
                                         alt={t(dungeon.name)}
                                         style={{borderRadius: '5%'}}
                                    />
                                </Grid>
                                {dungeon.items.map(item => (
                                    <Grid key={item.id} item xs={4} sm={2} md={1} mt={1} style={{textAlign: 'center', position:'relative'}}>
                                        <SetItem id={item.id}/>
                                        <span className={'item-difficulty di-text'}>{getDifficulty(item, dungeon.id)}+</span>
                                    </Grid>
                                ))}
                            </Grid>
                        ))}
                </CardContent>
            </Card>
        </div>
    );
};

SelectedItemsList.propTypes = {

};

export default SelectedItemsList;