import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";
import LegendaryGemIcon from "./LegendaryGemIcon";

const GemQuantity = ({gemId, quantity, rank}) => {
    const {t} = useTranslation();
    return (
        <div className={`di-gem-image`} style={{position: 'relative'}}>
            <LegendaryGemIcon id={gemId}/>
            <span className={'gem-rank-label'}>{t('rank')} {rank}</span>
            <div className={'quantity'}> {quantity}</div>
        </div>
    );
};

GemQuantity.propTypes = {
    gemId: PropTypes.number.isRequired,
    quantity: PropTypes.number,
    rank: PropTypes.number
};

export default GemQuantity;