import React from 'react';
import {useTheme} from "@mui/material/styles";
import {Box, FormControl, Grid, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import Gem from "./Gem";
import {useStoreState} from "../../store";
import useResponsive from "../../hooks/useResponsive";
import GemUpgradeTable from "./GemUpgradeTable";

const LEVELS = Array.from({length: 10}, (_, i) => i + 1)

const GemConfigurator = () => {
    const {t} = useTranslation();
    const [lvl, setLvl] = React.useState(1);
    const gemState = useStoreState('gems');
    const theme = useTheme();
    const mdDown = useResponsive('down', 'md');

    const handleChange = (event) => {
        setLvl(event.target.value);
    };

    return (
        <Box>
            <Grid
                container
                spacing={3}
                alignItems="center"
                justifyContent="center"
                style={{marginBottom: 20}}
            >
                {!gemState.selectedGem ?
                    !mdDown && <Typography variant="h3">{t('selectGem')}</Typography>
                    :
                    <>
                        <Grid item>
                            <FormControl>
                                <InputLabel id="level-select">{t('rank')}</InputLabel>
                                <Select
                                    labelId="level-select"
                                    id="level-select"
                                    value={lvl}
                                    label="Level"
                                    MenuProps={{
                                        MenuListProps: {
                                            style:{background: theme.palette.background.DI}
                                        }
                                    }}
                                    onChange={handleChange}
                                >
                                    {
                                        LEVELS.map(lvl => <MenuItem key={lvl} value={lvl}>{lvl}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                    </>
                }
            </Grid>
            <Grid
                container
                spacing={0}
            >
                <Grid item xs={12} sm={12} md={5}
                      alignItems="center"
                      justifyContent="center">
                    <Gem lvl={lvl}/>
                </Grid>
                 <Grid item xs={12} sm={12} md={7} textAlign="center">
                    { gemState.selectedGem && <GemUpgradeTable lvl={lvl}/> }
                 </Grid>
            </Grid>
        </Box>
    );
};

GemConfigurator.propTypes = {

};

export default GemConfigurator;