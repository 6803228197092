import React from 'react';
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {Box, Input, List, Select} from "@mui/material";
import {GEMS} from "../../constants";
import {useStoreState} from "../../store";
import useResponsive from "../../hooks/useResponsive";
import GemButton from "./GemButton";

const GemsList = () => {
    const {t} = useTranslation();
    const gemState = useStoreState('gems');
    const [open, setOpen] = React.useState(false);

    const theme = useTheme()
    const mdDown = useResponsive('down', 'md');

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };
    return (
        <Box sx={{width: '100%'}}>
            <nav>
                {mdDown ?
                    <Select
                        labelId="small-screen-gem-select"
                        id="small-screen-gem-select"
                        style={{width: '100%',marginBottom: 20}}
                        open={open}
                        value={GEMS[0].id || ''}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        renderValue={()=> {
                            if(gemState.selectedGem) {
                                return t(`gems.${gemState.selectedGem.i18n}`)
                            }
                            return t('selectGem')
                        }}
                        MenuProps={{
                            MenuListProps: {
                                style:{background: theme.palette.background.DI}
                            }
                        }}
                        input={<Input placeholder={"test"}/>}
                    >
                        {GEMS.map((gem, i) => <GemButton value={gem.id} key={gem.id} gem={gem}
                                              isLast={i + 1 === GEMS.length}
                                              onSelect={() => setOpen(false)}/>
                        )}
                    </Select>
                    :
                    <List>
                        {GEMS.map(gem => <GemButton key={gem.id} gem={gem}/> )}
                    </List>
                }
            </nav>
        </Box>
    );
};

GemsList.propTypes = {
    
};

export default GemsList;