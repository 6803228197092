import {
    CLEAR_AWAKENING,
    CLEAR_SELECTED_LEGENDARY_GEM,
    LEGENDARY_GEM_SEARCH,
    LEGENDARY_GEM_SELECT, SET_AWAKENING,
} from "../constants";
import {getLegendaryGem} from "../utils/items";

export default (state, action) => {
    // console.log('state', state)
    switch (action.type) {
        case LEGENDARY_GEM_SELECT:
            return {...state, selectedGem: getLegendaryGem(action.payload)};
        case LEGENDARY_GEM_SEARCH:
            return {...state, searchValue: action.payload};
        case CLEAR_SELECTED_LEGENDARY_GEM:
            return {...state, selectedGem: null};
        case SET_AWAKENING:
            return {...state, awakening: action.payload};
        case CLEAR_AWAKENING:
            return {...state, awakening: {}};
        default:
            return state;
    }
};