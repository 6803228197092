import React from 'react';
import {Divider, Grid} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useStoreState} from "../../store";
import {GEM_1STAR, GEM_2STARS, GEM_5STARS, LEGENDARY_GEMS} from "../../constants";
import Star from "../stars/Star";
import Stars from "../stars/Stars";
import LegendaryGemButton from "./LegendaryGemButton";
import LegendaryGemConfigurator from "./LegendaryGemConfigurator";
import useResponsive from "../../hooks/useResponsive";

const LegendaryGemsFilteredList = () => {
    const {t} = useTranslation();
    const gemState = useStoreState('legendaryGems');
    const gems = LEGENDARY_GEMS.filter(gem=>{
        const gemName = t(`legendaryGems.${gem.i18n}.name`).toLowerCase()
        const searchVal = gemState.searchValue.toLowerCase()
        return gemName.includes(searchVal)
    })

    const mdDown = useResponsive('down', 'md');

    const groups = gems.reduce((groups, gem) => ({
        ...groups,
        [gem.type]: [...(groups[gem.type] || []), gem]
    }), {});

    const GroupHeader = ({type}) =>{
        let header;
        switch (Number(type)) {
            case GEM_1STAR:
                header = <div><Star active/><Divider/></div>
                break;
            case GEM_2STARS:
                header = <div><Stars amount={2} activeNum={2}/><Divider/></div>
                break;
            case GEM_5STARS:
                header = <div><Stars amount={5} activeNum={2} unknownNum={3}/><Divider/></div>
                break;
            default:
                header = ''
                break;
        }
        return header;
    }
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={mdDown ? 12 : 3}>
            {
                Object.keys(groups).map((key)=>
                    <div key={key} style={{marginBottom: '2em'}}>
                        <GroupHeader type={key}/>
                        {groups[key].map(gem=> <LegendaryGemButton key={gem.id} gem={gem}/> )}
                    </div>
                )
            }
            </Grid>
            <Grid item xs={12} md={8} sm={12}>
                <LegendaryGemConfigurator/>
            </Grid>
        </Grid>
    );
};

LegendaryGemsFilteredList.propTypes = {

};

export default LegendaryGemsFilteredList;